/** @jsxImportSource theme-ui */
import * as React from "react";

import CTA from "./cta";

const Accordion = ({ className, services }) => {
  return (
    <div className={className}>
      {services.map(({ _key, ...service }) => (
        <AccordionItem key={_key} {...{ service }} />
      ))}
    </div>
  );
};

const AccordionItem = ({ service }) => {
  const [active, setActive] = React.useState(false);

  return (
    <div
      sx={{
        px: "1rem",
        py: "1.25rem",
        borderBottom: "1px solid rgba(35, 31, 32, 0.15)",
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setActive(!active)}
      >
        <p
          sx={{
            fontSize: "1.25rem",
            fontWeight: "medium",
          }}
          dangerouslySetInnerHTML={{ __html: service.title }}
        />
        <Icon
          sx={{
            transform: active ? "rotate(45deg)" : "",
          }}
        />
      </div>
      {active && (
        <div>
          <p
            sx={{
              py: "1rem",
            }}
            dangerouslySetInnerHTML={{ __html: service.text }}
          />
          {/* eslint-disable-next-line */}
          {service.cta && <CTA variant="blue" cta={service.cta} />}
        </div>
      )}
    </div>
  );
};

const Icon = ({ className }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...{ className }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 0C5.11194 0 5 0.111923 5 0.25V5H0.25C0.111938 5 0 5.11192 0 5.25V6.75C0 6.88808 0.111938 7 0.25 7H5V11.75C5 11.8881 5.11194 12 5.25 12H6.75C6.88806 12 7 11.8881 7 11.75V7H11.75C11.8881 7 12 6.88808 12 6.75V5.25C12 5.11192 11.8881 5 11.75 5H7V0.25C7 0.111923 6.88806 0 6.75 0H5.25Z"
        fill="#010101"
      />
    </svg>
  );
};

export default Accordion;
