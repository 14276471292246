/** @jsxImportSource theme-ui */
import * as React from "react";
import { Container } from "theme-ui";
import Accordion from "../components/accordion";

import Image from "../components/image";

const Services = ({ data }) => {
  const { heading, text, image, services, anchor } = data;

  return (
    <div
      id={anchor}
      sx={{
        bg: "#F1F7F6",
        pt: "4rem",
        pb: "10rem",
      }}
    >
      <Container>
        <h2
          dangerouslySetInnerHTML={{ __html: heading }}
          sx={{ mb: "2rem" }}
        ></h2>
        <div
          sx={{
            display: "grid",
            gridTemplateColumns: ["100%", "100%", "repeat(2, minmax(0, 1fr))"],
            gap: "3.75rem",
          }}
        >
          <div sx={{}}>
            <p dangerouslySetInnerHTML={{ __html: text }} />
            <Image
              source={image}
              sx={{
                mt: "2.5rem",
                width: "100%",
                objectFit: "cover",
                objectPosition: "center",
                aspectRatio: "1.5",
              }}
            />
          </div>
          <div>
            <Accordion services={services} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;
